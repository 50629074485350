import * as yup from 'yup';
import { formatDateShort } from '../../../shared/helpers/dateHelpers';

const rotationSchemaFields = {
  specialty: yup.array()
    .min(1, 'At least one item should be selected')
    .required('Field is required'),
  dateRange: yup.array()
    .min(2, 'Start Date and End Date should be selected')
    .test(
      'not-null',
      'Field is required',
      val => val.length > 0 && val.every(v => v !== null),
    )
    .test(
      'valid-date-format',
      'The date should be in mm/dd/yy format',
      val => val.every(v => v === null || (v instanceof Date && !Number.isNaN(Date.parse(v)))),
    )
    .test(
      'valid-date-range',
      'Start Date should be before End Date',
      val => {
        if (val.every(v => v !== null && v instanceof Date && !Number.isNaN(Date.parse(v)))) {
          return val[0] <= val[1];
        }
        return true;
      },
    )
    .test(
      'in-future',
      'This date should be in the future',
      val => {
        if (val.every(v => v !== null && v instanceof Date && !Number.isNaN(Date.parse(v)))) {
          return val.every(v => v >= new Date(new Date().setHours(0, 0, 0, 0)));
        }
        return true;
      },
    )
    .test(
      'inside-avail-dates',
      // eslint-disable-next-line func-names
      function (val) {
        if (val.every(v => v === null || !(v instanceof Date && !Number.isNaN(Date.parse(v))))) return true;
        if (!this.options.context || !('availDates' in this.options.context)) return true;

        const availDates = this.options.context.availDates.map(d => d.getTime());
        const valStartDate = val[0] ? val[0].valueOf() : null;
        const valEndDate = val[1] ? val[1].valueOf() : null;

        const outsideAvailability = !(availDates.includes(valStartDate) && availDates.includes(valEndDate));
        if (outsideAvailability) {
          const nearestStartDate = new Date(Math.min(...availDates.filter(d => d >= valStartDate)));
          const nearestEndDate = new Date(Math.max(...availDates.filter(d => d <= valEndDate)));
          return this.createError({
            message: `This preceptor can precept you from ${formatDateShort(nearestStartDate)} to ${formatDateShort(nearestEndDate)}. Please change dates accordingly.`,
          });
        }

        return true;
      },
    )
    .required('Field is required'),
  paperworkDueDate: yup.date()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'This date should be in the future')
    .typeError('This field must be a date')
    .required('This field is required'),
  hours: yup.number()
    .typeError('This field must be a number')
    .positive('This number must be a positive')
    .integer('This number must be an integer')
    .max(1000, 'This number must be at most 1000')
    .required('This field is required'),
  whichRotationThisWillBeForStudent: yup.number()
    .typeError('This field must be a number')
    .positive('This number must be a positive')
    .integer('This number must be an integer')
    .min(1, 'This number must be at least 1')
    .max(100, 'This number must be at most 100')
    .required('This field is required'),
  rotationNote: yup.string().nullable(),
  course: yup.string().when('requirements', {
    is: value => !!value,
    then: yup.string().required('Courase name is required if Rotation manual is filled'),
  }),
  program: yup.string().when('requirements', {
    is: value => !!value,
    then: yup.string().required('Program name is required if Rotation manual is filled'),
  }),
};

export const testPaperworkDueDateBeforeStartDate = value => {
  const { paperworkDueDate, dateRange } = value;
  if (!paperworkDueDate || !dateRange || dateRange.length < 2) return true;
  return paperworkDueDate <= dateRange[0];
};

export default rotationSchemaFields;
