import { gql } from '@apollo/client/core';

export const ME = gql`
  query me {
    me {
      uuid
      firebaseUid
      hasRole
      emailVerified
      isStaff
      isSuperuser
      crispHmac
      email
      getName
      owners {
        user {
          email
          getName
        }
      }
      student {
        id
        uuid
        profile {
          avatar
        }
      }
      preceptor {
        id
        uuid
        profile {
          avatar
        }
      }
      lastUsedRole {
        key
        value
      }
      wizardState {
        isWizardCompleted
        lastWizardStep
      }
    }
  }
`;

export const CREATE_STUDENT = gql`
  mutation createStudent ($firstName: String, $lastName: String, $nickname: String) {
    createStudent (firstName: $firstName, lastName: $lastName, nickname: $nickname) {
      created
    }
  }
`;

export const CREATE_PRECEPTOR = gql`
  mutation createPreceptor ($firstName: String, $lastName: String, $nickname: String) {
    createPreceptor (firstName: $firstName, lastName: $lastName, nickname: $nickname) {
      created
    }
  }
`;

export const VERIFY_EMAIL = gql`
  query verificationHash ($linkHash: String!) {
    verificationHash (linkHash: $linkHash) {
      emailVerified
    }
  }
`;

export const RESEND_VERIFICATION_LINK = gql`
  mutation resendVerificationLink {
    resendVerificationLink {
      eventUuid
    }
  }
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation sendResetPasswordLink ($email: String!) {
    sendResetPasswordLink (email: $email) {
      status
      message
    }
  }
`;

export const REQUEST_CUSTOM_SEARCH = gql`
  mutation requestCustomSearch {
    requestCustomSearch {
      success
    }
  }
`;
