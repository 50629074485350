import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

import {
  Route, Switch, BrowserRouter,
} from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import { UserProvider } from 'contexts/UserContext';
import { DataProvider } from 'contexts/DataContext';
import RouterWithLayout from 'shared/HOCs/RouteWithLayout';
import { store } from 'store';
import SignInDialog from 'apps/account/components/SignInDialog';
import Account from 'components/Account';
import Forbidden from 'components/Forbidden';
import Page404 from 'components/Page404';

import NewPasswordContainer from 'containers/NewPasswordContainer';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

import SearchPublicLayout from 'layouts/SearchPublicLayout';
import PublicLayout from 'layouts/PublicLayout';
import theme from 'theme';

import 'App.css';
import DesignSystem from 'components/DesignSystem';
import ForgotPasswordDialog from 'apps/account/components/ForgotPasswordDialog';
import DialogWelcome from 'apps/account/components/DialogWelcome';
import paths from 'paths';
import firebaseConfig from 'firebaseConfig';
import Snackbar from 'components/Snackbar';

// ---- ROUTES ---- //
import AccountRoutes from 'apps/account/Routes';
import SearchPublicRoutes from 'apps/searchPublic/Routes';
import CommonRoutes from 'apps/common/Routes';
import RotationRoutes from 'apps/rotation/Routes';
import WizardRoutes from 'apps/wizard/Routes';
import FinancesRoutes from 'apps/finances/Routes';
import StudentProfileRoutes from 'apps/studentProfile/Routes';
import PreceptorRoutes from 'apps/preceptor/Routes';
import SettingsRoutes from 'apps/settings/Routes';
import VerifyEmail from 'shared/components/VerifyEmail';
import CustomSearchPage from 'apps/account/pages/CustomSearch';
import CustomSearchLayout from 'layouts/CustomSearchLayout';
import FavoritesRoutes from 'apps/favoritePreceptors/Routes';
// ---- END ROUTES ---- //

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorBoundary';

import { FeatureFlagsProvider } from './contexts/FeatureFlagsContext';
import FirebaseAuthProvider from './contexts/FirebaseAuthProvider';
import AuthApolloProvider from './contexts/AuthApolloProvider';

const App = () => {
  useEffect(() => {
    let script;
    if (process.env.REACT_APP_TARGET_ENV === 'prod') {
      script = document.createElement('script');
      script.src = process.env.REACT_APP_GA_URL;
      script.async = true;
      document.head.appendChild(script);
    }
    return () => {
      if (process.env.REACT_APP_TARGET_ENV === 'prod') {
        document.head.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    let script;
    if (process.env.REACT_APP_TARGET_ENV === 'prod') {
      script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MR5339M');
      `;
      document.head.insertBefore(script, document.head.firstChild);
    }
    return () => {
      if (process.env.REACT_APP_TARGET_ENV === 'prod') {
        document.head.removeChild(script);
      }
    };
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <FirebaseAuthProvider>
              <AuthApolloProvider>
                <FeatureFlagsProvider>
                  <DataProvider>
                    <BrowserRouter>
                      <div className="App">
                        <LocalizationProvider dateAdapter={DateFnsUtils}>
                          <UserProvider>
                            <Switch>
                              <RouterWithLayout
                                path="/design-system"
                                layout={SearchPublicLayout}
                                loginRequired={false}
                                component={DesignSystem}
                              />
                              <RouterWithLayout
                                path="/new-password"
                                layout={PublicLayout}
                                loginRequired={false}
                                component={NewPasswordContainer}
                              />
                              <RouterWithLayout
                                path={paths.accountPaths.customSearch.pattern}
                                layout={CustomSearchLayout}
                                loginRequired={false}
                                component={CustomSearchPage}
                              />
                              <Route path="/app/forbidden" component={Forbidden} />
                              <Route path="/app/account" component={Account} />
                              <Route path={paths.commonPaths.verifyEmail.pattern} component={VerifyEmail} />
                              <Route
                                path={[
                                  paths.accountPaths.signIn.pattern,
                                  paths.accountPaths.signUp.pattern,
                                ]}
                                exact
                                component={AccountRoutes}
                              />
                              <Route
                                path={[
                                  paths.wizardPaths.welcome.pattern,
                                  paths.wizardPaths.wizardStart.pattern,
                                  paths.wizardPaths.wizardCompleted.pattern,
                                ]}
                                exact
                                component={WizardRoutes}
                              />
                              <Route
                                path={[
                                  paths.rotationPaths.confirmRotationStart.pattern,
                                  paths.rotationPaths.acceptStudent.pattern,
                                  paths.rotationPaths.rejectStudent.pattern,
                                  paths.rotationPaths.rotationReservationPayment.pattern,
                                  paths.rotationPaths.rotationAllPreceptors.pattern,
                                  paths.rotationPaths.rotationFavorites.pattern,
                                  paths.rotationPaths.rotationCanceled.pattern,
                                  paths.rotationPaths.rotationPreceptor.pattern,
                                  paths.rotationPaths.rotationPaperwork.pattern,
                                  paths.rotationPaths.rotationPayments.pattern,
                                  paths.rotationPaths.rotationPreferences.pattern,
                                  paths.rotationPaths.all.pattern,
                                  paths.rotationPaths.new.pattern,
                                  paths.rotationPaths.upcoming.pattern,
                                  paths.rotationPaths.inProgress.pattern,
                                  paths.rotationPaths.completed.pattern,
                                  paths.rotationPaths.canceled.pattern,
                                  paths.rotationPaths.refunded.pattern,
                                ]}
                                exact
                                component={RotationRoutes}
                              />
                              <Route
                                path={[
                                  paths.financesPaths.history.pattern,
                                  paths.financesPaths.myCards.pattern,
                                ]}
                                exact
                                component={FinancesRoutes}
                              />
                              <Route
                                path={[
                                  paths.commonPaths.privacyPolicy.pattern,
                                  paths.commonPaths.termsAndConditions.pattern,
                                  paths.commonPaths.refundPolicy.pattern,
                                ]}
                                exact
                                component={CommonRoutes}
                              />
                              <Route
                                path={[
                                  paths.studentProfilePaths.studentProfile.pattern,
                                ]}
                                exact
                                component={StudentProfileRoutes}
                              />
                              <Route
                                path={[
                                  paths.preceptorPaths.listView.pattern,
                                  paths.preceptorPaths.calendarView.pattern,
                                  paths.preceptorPaths.honorarium.pattern,
                                  paths.preceptorPaths.payouts.pattern,
                                  paths.preceptorPaths.student.pattern,
                                  paths.preceptorPaths.paperwork.pattern,
                                  paths.preceptorPaths.payments.pattern,
                                  paths.preceptorPaths.profileForPreceptor.pattern,
                                ]}
                                exact
                                component={PreceptorRoutes}
                              />
                              <Route
                                path={[
                                  paths.searchPublicPaths.publicSearch.pattern,
                                  paths.searchPublicPaths.preceptorProfile.pattern,
                                  paths.searchPublicPaths.publicSearchPreceptor.pattern,
                                ]}
                                exact
                                component={SearchPublicRoutes}
                              />
                              <Route
                                path={[
                                  paths.settingsPaths.changePassword.pattern,
                                ]}
                                exact
                                component={SettingsRoutes}
                              />
                              <Route
                                path={[
                                  paths.favoritesPaths.allFavoritePreceptors.pattern,
                                ]}
                                component={FavoritesRoutes}
                              />
                              {/* <>
                              </> */}
                              <Route component={Page404} />
                            </Switch>
                            <SignInDialog />
                            <ForgotPasswordDialog />
                            <DialogWelcome />
                            <Snackbar />
                          </UserProvider>
                        </LocalizationProvider>
                      </div>
                    </BrowserRouter>
                  </DataProvider>
                </FeatureFlagsProvider>
              </AuthApolloProvider>
            </FirebaseAuthProvider>
          </FirebaseAppProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
