import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import BigPreceptorCard from 'shared/components/PreceptorCard/partials/BigPreceptorCard';
import SmallPreceptorCard from 'shared/components/PreceptorCard/partials/SmallPreceptorCard';
import useQueryString from 'shared/hooks/useQueryString';
import paths from 'paths';
import { useBackendUserState } from 'contexts/UserContext';

const PreceptorCard = ({
  accountUUID, accountUID, generalLocation, credentials, typeOfPractice,
  availability, specialty, averageNumberOfPatientsPerDay, noOfStudentsPrecepted, small,
  isPeriodicalAvailable,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { state: { user: backendUser } } = useBackendUserState();
  const wizardNotCompleted = !backendUser?.wizardState?.isWizardCompleted;

  const credentialsString = credentials.map(el => el.value).join(', ');
  const { urlParams: { dateFrom, dateTo, hours } = {} } = useQueryString();

  const handleClick = event => {
    event.stopPropagation();

    if (backendUser === undefined) {
      history.push({
        pathname: paths.searchPublicPaths.publicSearchPreceptor.reverse({ uuid: accountUUID }),
        search,
      });
      return 0;
    }

    if (backendUser?.wizardState === null) {
      history.push({
        pathname: paths.wizardPaths.welcome.reverse(),
      });
      return 0;
    }

    if (wizardNotCompleted) {
      history.push({
        pathname: paths.wizardPaths.wizardStart.reverse({ slug: backendUser?.wizardState?.lastWizardStep }),
      });
      return 0;
    }

    history.push({
      pathname: paths.searchPublicPaths.publicSearchPreceptor.reverse({ uuid: accountUUID }),
      search,
    });
    return 0;
  };

  if (small) {
    return (
      <SmallPreceptorCard
        accountUID={accountUID}
        generalLocation={generalLocation}
        typeOfPractice={typeOfPractice}
        dateFrom={dateFrom}
        dateTo={dateTo}
        hours={hours}
        availability={availability}
        specialty={specialty}
        averageNumberOfPatientsPerDay={averageNumberOfPatientsPerDay}
        credentialsString={credentialsString}
        noOfStudentsPrecepted={noOfStudentsPrecepted}
        handleClick={handleClick}
        isPeriodicalAvailable={isPeriodicalAvailable}
      />
    );
  }
  return (
    <BigPreceptorCard
      accountUID={accountUID}
      generalLocation={generalLocation}
      typeOfPractice={typeOfPractice}
      availability={availability}
      specialty={specialty}
      averageNumberOfPatientsPerDay={averageNumberOfPatientsPerDay}
      credentialsString={credentialsString}
      handleClick={handleClick}
      isPeriodicalAvailable={isPeriodicalAvailable}
    />
  );
};

PreceptorCard.defaultProps = {
  accountUID: '',
  generalLocation: '',
  credentials: [],
  typeOfPractice: [],
  availability: '',
  specialty: [],
  averageNumberOfPatientsPerDay: null,
  noOfStudentsPrecepted: { key: 0, value: null },
  small: false,
  isPeriodicalAvailable: false,
};

PreceptorCard.propTypes = {
  accountUUID: PropTypes.string.isRequired,
  accountUID: PropTypes.string,
  generalLocation: PropTypes.string,
  credentials: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  typeOfPractice: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  availability: PropTypes.number,
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  averageNumberOfPatientsPerDay: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  noOfStudentsPrecepted: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  small: PropTypes.bool,
  isPeriodicalAvailable: PropTypes.bool,
};

export default PreceptorCard;
