import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Box, Typography, Button, IconButton, Link,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
// import ChatIcon from '@material-ui/icons/Chat';
import Cookies from 'js-cookie';
import paths from 'paths';
import { useQuery } from '@apollo/client';
import { MY_ROTATIONS } from 'shared/queries/rotation/queries';
import { useSigninCheck } from 'reactfire';
import rotationStatuses from 'apps/rotation/constants/rotationStatuses';
import { Elements } from '@stripe/react-stripe-js';
import paymentDescriptions from 'apps/finances/constants/paymentDescriptions';
import RequestCustomSearchDialog from 'shared/components/RequestCustomSearchDialog';
import chatStyles from './styles';

const Chat = () => {
  const classes = chatStyles();
  const theme = useTheme();
  const [hideChat, setHideChat] = useState(Cookies.get('hideChat'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data: signInCheckResult } = useSigninCheck();
  const isUserAuthenticated = signInCheckResult.signedIn;
  const [anchorEl, setAnchorEl] = useState(null);
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    import('@stripe/stripe-js').then(({ loadStripe }) => {
      // Load Stripe only once when the component is first rendered to increase performance
      loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY).then(stripeInstance => {
        setStripe(stripeInstance);
      });
    });
  }, []);

  const handleClose = () => {
    Cookies.set('hideChat', true, { expires: 1 });
    setHideChat(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { data: { myRotations = [] } = {} } = useQuery(MY_ROTATIONS, {
    skip: !isUserAuthenticated,
  });

  const rotationToDisplay = myRotations.results?.filter(
    rotation => rotation.status.key === rotationStatuses.new
    && !rotation?.customSearchRequestStatus && !rotation?.currentRequest,
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const getCustomSearchFirstPaymentStatus = payments => {
    if (payments) {
      const res = payments?.filter(item => {
        if (item.description.key === paymentDescriptions.customSearchPaymentOne) {
          return item;
        }
        return null;
      });
      return res[0]?.status?.key;
    }
    return null;
  };

  // eslint-disable-next-line react/prop-types
  const CustomSearchButton = ({ onClick }) => (
    <Button
      onClick={onClick}
      style={{ position: 'inherit', padding: '0px' }}
      variant="contained"
      size="medium"
    >
      Request Custom Search
    </Button>
  );

  const renderRotations = () => {
    if (rotationToDisplay?.length > 1) {
      return (
        <>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleClick}>Create Request Custom search</Link>
          {stripe && (
            <Elements stripe={stripe}>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 0,
                  horizontal: 'center',
                }}
                getContentAnchorEl={null}
                PopoverClasses={{ paper: classes.popover }}
              >
                {rotationToDisplay?.map(rotation => (
                  <RequestCustomSearchDialog
                    startDate={rotation.startDate}
                    endDate={rotation.endDate}
                    hours={rotation.hours}
                    specialty={rotation.specialty}
                    owners={rotation.owners}
                    rotationUID={rotation.uid}
                    rotationUuid={rotation.uuid}
                    key={rotation.uuid}
                    setProcessing={rotation.setProcessing}
                    errorAdd={rotation.errorAdd}
                    error={rotation.error}
                    setEmpty={rotation.setEmpty}
                    setErrorAdd={rotation.setErrorAdd}
                    setError={rotation.setError}
                    ButtonComponent={MenuItem}
                    buttonText={rotation.uid}
                    customSearchRequestStatus={rotation.customSearchRequestStatus}
                    firstPaymentStatus={getCustomSearchFirstPaymentStatus(rotation.payments || [])}
                  />
                ))}
              </Menu>
            </Elements>
          )}
        </>
      );
    } if (rotationToDisplay?.length > 0) {
      const rotation = rotationToDisplay[0];
      return (
        stripe && (
          <Elements stripe={stripe}>
            <RequestCustomSearchDialog
              startDate={rotation.startDate}
              endDate={rotation.endDate}
              hours={rotation.hours}
              specialty={rotation.specialty}
              owners={rotation.owners}
              rotationUID={rotation.uid}
              key={rotation.uuid}
              setProcessing={rotation.setProcessing}
              errorAdd={rotation.errorAdd}
              error={rotation.error}
              setEmpty={rotation.setEmpty}
              setErrorAdd={rotation.setErrorAdd}
              setError={rotation.setError}
              customSearchRequestStatus={rotation.customSearchRequestStatus}
              firstPaymentStatus={getCustomSearchFirstPaymentStatus(rotation.payments || [])}
              ButtonComponent={CustomSearchButton}
              rotationUuid={rotation.uuid}
              buttonText="Create Request Custom Search"
            />
          </Elements>
        )
      );
    }

    if (hideChat) return null;

    return (
      <Link component={RouterLink} to={paths.wizardPaths.welcome.reverse()}>
        Complete your profile
      </Link>
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.block}>
        <Box px={isMobile ? 2 : 5}>
          <Typography variant={isMobile ? 'body1' : 'h6'} className={classes.text}>
            Can’t find the Preceptor you are looking for?
          </Typography>
          <Typography className={classes.textChat}>
            If your options are limited, consider moving your dates out to the future.
            It might be worth the wait!
            <br />
            Or chat with one of our team members,
            and we will help you out!
          </Typography>
        </Box>
        <IconButton className={classes.iconClose} onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.button}>
        {isUserAuthenticated
          ? (myRotations.results?.length === 0 || rotationToDisplay?.length !== 0) && (
            <Button style={{ position: 'inherit' }} variant="contained" size="medium">
              {renderRotations()}
            </Button>
          ) : (
            <Link className={classes.customSearchLink} target="_blank" href={paths.accountPaths.customSearch.reverse()}>
              <Button style={{ position: 'inherit' }} variant="contained" size="medium">
                Custom Search
              </Button>
            </Link>
          )}
      </Box>
    </Box>
  );
};

export default Chat;
