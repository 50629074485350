import React, { useEffect, useState } from 'react';
import { useBackendUserState } from 'contexts/UserContext';
import { useSigninCheck } from 'reactfire';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useQueryString from 'shared/hooks/useQueryString';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Box, Grid, Typography, Divider, MenuItem, Button,
  FormControl, FormHelperText, TextField, Select, InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
  MY_ROTATIONS, ROTATION, ROTATION_MUTATION, CONFLICTING_ROTATIONS,
} from 'shared/queries/rotation/queries';
import { CANCELED_PRECEPTORS } from 'shared/queries/searchPublic/queries';
import { openSignUp } from 'actions/authDialogActions';
import { Controller, useForm, useWatch } from 'react-hook-form';
import rotationPaymentTypes from 'apps/rotation/constants/rotationPaymentTypes';
import rotationSchemaFields, {
  testPaperworkDueDateBeforeStartDate,
} from 'apps/rotation/constants/rotationSchemaFields';
import rotationPaymentTypeOptions from 'apps/rotation/constants/rotationPaymentTypeOptions';
import { dateShortFormat } from 'shared/constants/dateFormats';
import { DatePicker } from '@material-ui/pickers';
import { Clear } from '@material-ui/icons';
import { formatIsoDate } from 'shared/helpers/dateHelpers';
import ButtonProgress from 'shared/components/ButtonProgress';
import RangeDatePicker from 'shared/components/RangeDatePicker';
import ConflictingRotations from 'shared/components/ConflictingRotations';
import AutocompleteSpecialty from 'shared/components/AutocompleteSpecialty';
import CircularProgressCustom from 'shared/components/CircularProgressCustom';
import { yupResolver } from '@hookform/resolvers/yup';
import SNACKBAR_OPEN from 'actions/snackbarAction';
import * as yup from 'yup';
import paths from 'paths';
import rotationStatuses from 'apps/rotation/constants/rotationStatuses';
import rotationPaperworkStatuses from 'apps/rotation/constants/rotationPaperworkStatuses';
import useRotationPriceCalculator from 'shared/hooks/useRotationPriceCalculator';
import ConfirmationDialog from 'shared/components/InlineEditField/partials/ConfirmationDialog';
import RotationCostBreakdown from '../../../../../shared/components/RotationCostBreakdown';
import expandedReservationBlockStyles from './styles';
import PaymentsPercentageBreakdown from '../../../../rotation/components/PaymentsPercentageBreakdown';
import PromoCodeField from './partials/PromoCodeField';

const schema = yup.object().shape({
  ...rotationSchemaFields,
  paymentType: yup.string().nullable().required('Field is required'),
}).test(
  'paperwork-due-date-before-start-date',
  'Paperwork date should be at least 1 day before Rotation start day',
  testPaperworkDueDateBeforeStartDate,
);

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const getDatesBetweenDates = (fromDate, toDate) => {
  let dates = [];
  const endDate = new Date(typeof toDate === 'object' || toDate?.includes('T00:00') ? toDate : `${toDate}T00:00`);
  const theDate = new Date(typeof fromDate === 'object' || fromDate?.includes('T00:00') ? fromDate : `${fromDate}T00:00`);
  while (theDate <= endDate) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }
  return dates;
};

export const getHoursCoveredInRotation = (dates, availSettings, hoursCapPerRotation) => {
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayHours = availSettings?.days.reduce((p, c) => ({ ...p, [c.weekday.value]: c.hours }), {});
  const availabilityHours = parseInt(
    dates.map(date => dayHours[dayNames[date.getDay()]]).reduce((p, c) => p + c, 0), 10,
  );
  return Math.min(availabilityHours, hoursCapPerRotation || 1000000);
};

const ExpandedReservationBlock = ({
  profile, preceptorID, availDates, honorariumHourRanges, availSettings,
}) => {
  const classes = expandedReservationBlockStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDueDate, setOpenDueDate] = useState(false);
  const [warningDueDate, setWarningDueDate] = useState(null);
  const { urlParams, setQueryString } = useQueryString();
  const { data: signInCheckResult } = useSigninCheck();
  const { state: { user: backendUser } } = useBackendUserState();
  const isUserAuthenticated = signInCheckResult.signedIn;
  const {
    uuid = '',
    specialty = [],
    dateFrom = null,
    dateTo = null,
    hours = null,
    whichRotationThisWillBeForStudent = null,
    rotationNote = '',
    paymentType = '',
    paperworkDueDate = null,
  } = urlParams;

  const [rotationMutation, { loading: loadingM }] = useMutation(ROTATION_MUTATION);

  const [getRotation, {
    loading: loadingR, error: rotationError, data: { rotation } = {}, called,
  }] = useLazyQuery(ROTATION);

  const [rotationPaid, setRotationPaid] = useState(false);

  useEffect(() => {
    setRotationPaid(Boolean(
      rotation?.uuid
      && (rotation?.status?.key !== rotationStatuses.new
        || rotation?.paperworkStatus?.key === rotationPaperworkStatuses.preceptorRequested),
    ));
  }, [rotation]);

  const [getConflictingRotation, {
    loading: loadingCR, data: { conflictingRotations } = {},
  }] = useLazyQuery(CONFLICTING_ROTATIONS, { fetchPolicy: 'cache-and-network' });

  const [getCancelledPreceptor, {
    called: calledCP, data: { canceledPreceptorProfiles = [] } = {},
  }] = useLazyQuery(CANCELED_PRECEPTORS);

  const isPreceptorCancelled = canceledPreceptorProfiles?.some(preceptor => preceptor?.account?.id === preceptorID);

  const msPerDay = 1000 * 60 * 60 * 24;
  const today = new Date(`${formatIsoDate(new Date())}T00:00`);
  let defaultPaperworkDueDate = paperworkDueDate && new Date(`${formatIsoDate(new Date(paperworkDueDate))}T00:00`);
  if (defaultPaperworkDueDate && defaultPaperworkDueDate < today) defaultPaperworkDueDate = today;
  const allowedDates = availDates.map(date => new Date(`${date.date}T00:00`));

  const {
    control, handleSubmit, setValue, setError, formState: { errors }, watch, trigger,
  } = useForm({
    resolver: yupResolver(schema),
    context: { availDates: allowedDates },
    mode: 'onSubmit',
    defaultValues: {
      specialty: Array.isArray(specialty) ? specialty : [specialty],
      dateRange: [dateFrom && new Date(`${dateFrom}T00:00`), dateTo && new Date(`${dateTo}T00:00`)],
      paperworkDueDate: defaultPaperworkDueDate,
      hours,
      whichRotationThisWillBeForStudent,
      rotationNote,
      paymentType: paymentType || rotationPaymentTypes.regular,
    },
  });

  const paperworkDueDateAfterStartDateError = errors['']?.type === 'paperwork-due-date-before-start-date'
    && errors['']?.message;

  const hoursError = errors?.hours?.type === 'manual'
    && errors?.hours?.message;

  const trueHours = useWatch({
    control,
    name: 'hours',
    defaultValue: hours || 0,
  });
  const trueDateRange = useWatch({
    control,
    name: 'dateRange',
    defaultValue: [dateFrom && `${dateFrom}T00:00`, dateTo && `${dateTo}T00:00`],
  });
  const truePaperworkDueDate = useWatch({
    control,
    name: 'paperworkDueDate',
    defaultValue: paperworkDueDate,
  });

  const newRotationStartDate = trueDateRange[0] && new Date(typeof trueDateRange[0] === 'object' || trueDateRange[0].includes('T00:00') ? trueDateRange[0] : `${trueDateRange[0]}T00:00`);
  const newPaperworkDueDate = truePaperworkDueDate && new Date(typeof truePaperworkDueDate === 'object' || truePaperworkDueDate.includes('T00:00') ? truePaperworkDueDate : `${truePaperworkDueDate}T00:00`);

  useEffect(() => {
    if (isUserAuthenticated && trueDateRange[0] && trueDateRange[1] && !loadingCR && !openDatePicker) {
      const startingDate = formatIsoDate(trueDateRange[0]);
      const endingDate = formatIsoDate(trueDateRange[1]);

      getConflictingRotation({
        variables: {
          start: startingDate,
          end: endingDate,
          uuid,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trueDateRange, openDatePicker]);

  useEffect(() => {
    const watchSubscription = watch(data => {
      let newData = { uuid, dateFrom: urlParams.dateFrom, dateTo: urlParams.dateTo };
      if (data?.dateRange[0] && data?.dateRange[1]) {
        newData = { ...newData, dateFrom: formatIsoDate(data.dateRange[0]), dateTo: formatIsoDate(data.dateRange[1]) };
      } if (parseInt(data?.hours, 10)) {
        newData = { ...newData, hours: data.hours };
      } if (parseInt(data?.whichRotationThisWillBeForStudent, 10)) {
        newData = { ...newData, whichRotationThisWillBeForStudent: data.whichRotationThisWillBeForStudent };
      } if (data?.rotationNote) {
        newData = { ...newData, rotationNote: data.rotationNote };
      } if (data?.paymentType) {
        newData = { ...newData, paymentType: data.paymentType };
      } if (data?.specialty) {
        newData = { ...newData, specialty: data.specialty };
      } if (data?.paperworkDueDate) {
        newData = { ...newData, paperworkDueDate: formatIsoDate(data.paperworkDueDate) };
      }
      setQueryString(newData);
    });
    return () => watchSubscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const selectedDates = getDatesBetweenDates(trueDateRange[0], trueDateRange[1]);
  const selectedAllowedDates = selectedDates.filter(date => allowedDates.some(d => d.valueOf() === date.valueOf()));
  const hoursCoveredInRotation = getHoursCoveredInRotation(
    selectedAllowedDates, availSettings, profile?.hoursCapPerRotation,
  );
  const canCoverNeededHours = trueHours <= hoursCoveredInRotation;

  const checkCanCoverNeededHours = () => {
    if (!canCoverNeededHours) {
      setError('hours', {
        type: 'manual',
        message: profile?.hoursCapPerRotation && trueHours > profile?.hoursCapPerRotation
          ? `This preceptor can only cover ${profile?.hoursCapPerRotation} hrs in this rotation.`
          : `This preceptor can only cover ${hoursCoveredInRotation} hrs during the time range you entered. Consider changing dates of your rotation or add another preceptor.`,
      });
    } else {
      setError('hours', null);
    }
  };

  useEffect(() => {
    checkCanCoverNeededHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trueDateRange, trueHours, errors]);

  const daysFromToday = Math.floor((newPaperworkDueDate - today) / msPerDay);
  const enoughTimeForPaperwork = !newPaperworkDueDate || daysFromToday >= 7;
  const checkPaperworkDueDate = () => {
    if (!enoughTimeForPaperwork) {
      setWarningDueDate('Your rotation paperwork due date is less than 7 days from today. While there is still a good chance that we can place you, please reach out to our team to confirm before making the reservation to avoid losing your non-refundable application fee.');
    } else {
      setWarningDueDate(null);
    }
  };

  useEffect(() => {
    checkPaperworkDueDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enoughTimeForPaperwork]);

  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);
  const [isPaymentPlanOpen, setPaymentPlanOpen] = useState(false);
  const [planSelected, setPlanSelected] = useState(null);

  useEffect(() => {
    if (rotation?.promoCode?.code) {
      setPromoCodeValue(rotation.promoCode.code);
    }
  }, [rotation?.promoCode?.code, setPromoCodeValue]);

  const {
    hourlyRate,
    serviceFee,
    lastMinuteFee,
    paymentPlanFee,
    total,
    initialTotal,
    dueToday,
    dueLater,
    loadingConstants,
    hideBreakdown,
  } = useRotationPriceCalculator({
    profile,
    trueHours,
    paperworkDueDate,
    dateFrom,
    honorariumHourRanges,
    paymentType,
    promoCodeDiscount,
    discount: rotation?.discount,
    extraCharges: rotation?.extraCharges,
    ignoreLMF: rotation?.ignoreLmf,
    previousRequestsPaidAmount: rotation?.previousRequestsPaidAmount,
  });

  const disableReserveButton = loadingM || loadingCR || isPreceptorCancelled || rotationPaid
  || (!isUserAuthenticated && hoursError);

  if (uuid && !called) getRotation({ variables: { uuid } });
  if (uuid && !calledCP) getCancelledPreceptor({ variables: { uuid } });

  useEffect(() => {
    if (rotation) {
      setValue('specialty', rotation.specialty.map(item => `${item.key}`));
      setValue('dateRange', [new Date(`${rotation.startDate}T00:00`), new Date(`${rotation.endDate}T00:00`)]);
      setValue('paperworkDueDate', new Date(`${rotation.paperworkDueDate}T00:00`));
      setValue('hours', rotation.hours);
      setValue('whichRotationThisWillBeForStudent', rotation.whichRotationThisWillBeForStudent);
      setValue('rotationNote', rotation.rotationNote);
      setValue('paymentType', rotation.paymentType.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation]);

  const createRotation = async data => {
    if (!await trigger()) return;
    const input = {
      ...data,
      preceptor: preceptorID,
      specialty: data.specialty.join(),
      startDate: formatIsoDate(data.dateRange[0]),
      endDate: formatIsoDate(data.dateRange[1]),
      paperworkDueDate: formatIsoDate(new Date(data.paperworkDueDate)),
      customSearchRequestStatus: rotation?.customSearchRequestStatus,
      customSearchRequestDate: rotation?.customSearchRequestDate,
      ignorePpf: data.paymentType !== rotationPaymentTypes.paymentPlan,
      code: promoCodeValue,
    };

    if (uuid) input.uuid = uuid;
    if (backendUser?.student) input.student = backendUser.student.id;
    delete input.dateRange;

    rotationMutation({
      variables: { input },
      refetchQueries: uuid ? [
        { query: MY_ROTATIONS }, { query: ROTATION, variables: { uuid } },
      ] : [
        { query: MY_ROTATIONS },
      ],
      awaitRefetchQueries: true,
    }).then(({ data: { rotation: response } }) => {
      history.push(paths.rotationPaths.rotationReservationPayment.reverse({ uuid: response.rotation.uuid }));
    }).catch(mutationError => {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        variant: 'alert',
        alertSeverity: 'error',
        message: mutationError.toString(),
      });
    });
  };

  const onSubmit = data => {
    if (!isUserAuthenticated) {
      dispatch(openSignUp());
      setQueryString({ ...urlParams, preceptorID, noRedirect: true });
    } else {
      createRotation(data);
    }
  };

  if (loadingR) {
    return (
      <Box className={classes.root}>
        <CircularProgressCustom height="100vh" />
      </Box>
    );
  }

  if (rotationError) {
    const errorMessage = rotationError?.graphQLErrors?.map(error => error.message)?.join(', ');
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      variant: 'alert',
      alertSeverity: 'error',
      message: errorMessage,
    });
    history.push(paths.rotationPaths.all.reverse());
    return <></>;
  }

  const cancelSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    await trigger();
    checkCanCoverNeededHours();
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h5">
        Reserve Your Rotation
      </Typography>
      <Box my={2} mx={-3}>
        <Divider />
      </Box>
      {isUserAuthenticated && (
        <Box>
          <FormControl variant="filled" fullWidth>
            {(uuid && called) ? (
              <>
                <InputLabel htmlFor="filled-age-native-simple">Rotation</InputLabel>
                <Select value={(uuid && called) ? uuid : 'new'} fullWidth MenuProps={menuProps}>
                  <MenuItem value={uuid} className={classes.item}>
                    <Box component="span" mb={1}>
                      Rotation ID:
                      {' '}
                      {rotation?.uid}
                    </Box>
                  </MenuItem>
                </Select>
              </>
            ) : (
              <Box component="span" mb={1}>
                Create New Rotation
              </Box>
            )}
          </FormControl>
          <Box my={1} display="flex" alignItems="center">
            <InfoIcon className={classes.icon} />
            {(uuid && called) ? (
              <Typography variant="caption" color="textSecondary">
                System will edit selected Rotation with these values once you click on “Reserve”.
              </Typography>
            ) : (
              <Typography variant="caption" color="textSecondary">
                System will create new Rotation with these values once you click on “Reserve”.
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <form onSubmit={
        errors.hours?.type === 'manual' || errors.paperworkDueDate?.type === 'manual'
          ? cancelSubmit
          : handleSubmit(onSubmit)
        }
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RangeDatePicker
                  open={openDatePicker}
                  setOpen={setOpenDatePicker}
                  value={value}
                  onChange={onChange}
                  error={errors.dateRange}
                  allowedDates={allowedDates}
                  minDate={new Date()}
                />
              )}
            />
          </Grid>
        </Grid>
        <ConflictingRotations conflictingRotations={conflictingRotations} />
        <Box mt={1} width="100%">
          <Grid item xs={12}>
            <Controller
              name="hours"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="# of Hours"
                  variant="filled"
                  type="number"
                  fullWidth
                  error={Boolean(errors.hours?.message)}
                  helperText={errors.hours?.message}
                  required
                />
              )}
            />
          </Grid>
        </Box>
        <Box mt={1} width="100%">
          <Grid item xs={12}>
            <Controller
              name="whichRotationThisWillBeForStudent"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Which rotation will this be for you?"
                  variant="filled"
                  type="number"
                  fullWidth
                  error={Boolean(errors.whichRotationThisWillBeForStudent?.message)}
                  helperText={errors.whichRotationThisWillBeForStudent?.message}
                  required
                />
              )}
            />
          </Grid>
        </Box>
        <Box mt={1} width="100%">
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth>
              <Controller
                name="specialty"
                control={control}
                render={({ field }) => (
                  <AutocompleteSpecialty
                    {...field}
                    length={35}
                    error={Boolean(errors.specialty?.message)}
                    helperText={errors.specialty?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Box>
        <Box mt={1} width="100%">
          <Grid item xs={12}>
            <Controller
              name="paperworkDueDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  minDate={new Date()}
                  maxDate={newRotationStartDate}
                  inputFormat={dateShortFormat}
                  value={value || null}
                  onChange={onChange}
                  mask=""
                  open={openDueDate}
                  onClose={() => setOpenDueDate(false)}
                  renderInput={props => (
                    <TextField
                      {...props}
                      label="Paperwork Due Date"
                      variant="filled"
                      fullWidth
                      onClick={() => setOpenDueDate(true)}
                      error={!!errors.paperworkDueDate?.message || !!paperworkDueDateAfterStartDateError}
                      helperText={
                        errors.paperworkDueDate?.message
                        || paperworkDueDateAfterStartDateError
                        || warningDueDate
                        || null
                      }
                      required
                      InputProps={
                        {
                          endAdornment:
                          (
                            <InputAdornment position="end">
                              <IconButton onClick={() => onChange(null)}>
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Box>
        <Box mt={1} width="100%">
          <Grid item xs={12}>
            <Controller
              name="rotationNote"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Notes about this Rotation"
                  variant="filled"
                  type="text"
                  fullWidth
                  multiline
                  error={Boolean(errors.rotationNote?.message)}
                  helperText={errors.rotationNote?.message}
                />
              )}
            />
          </Grid>
        </Box>
        <Box my={2} mx={-3}>
          <Divider />
        </Box>
        {loadingConstants ? (
          <Box my={2}>
            <CircularProgressCustom size={32} />
          </Box>
        ) : (
          <>
            <Box mb={2} width="100%">
              <Grid item xs={12}>
                <Controller
                  name="paymentType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl variant="filled" fullWidth error={Boolean(errors.paymentType?.message)}>
                      <InputLabel id="payment-type-select-label">Payment Type</InputLabel>
                      <Select
                        fullWidth
                        label="Payment Type"
                        labelId="payment-type-select-label"
                        value={value}
                        onChange={ev => {
                          if (ev.target.value === 'regular') {
                            setPlanSelected(ev);
                            onChange(ev);
                          } else {
                            setPaymentPlanOpen(true);
                            setPlanSelected(ev);
                          }
                        }}
                      >
                        {rotationPaymentTypeOptions.map(item => (
                          <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                        ))}
                      </Select>
                      {errors.paymentType?.message && (
                        <FormHelperText>{errors.paymentType?.message}</FormHelperText>
                      )}
                      <ConfirmationDialog
                        open={isPaymentPlanOpen}
                        content="You want a Payment Plan? There will be a 15% payment plan fee, and you must be paid in full before starting."
                        handleCancel={() => setPaymentPlanOpen(false)}
                        handleConfirm={() => { onChange(planSelected); setPaymentPlanOpen(false); }}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Box>
            {canceledPreceptorProfiles.length === 0 && (
              <Box mb={2} width="100%">
                <PromoCodeField
                  rotationDiscount={rotation?.discount}
                  promoCodeValue={promoCodeValue}
                  setPromoCodeValue={setPromoCodeValue}
                  setPromoCodeDiscount={setPromoCodeDiscount}
                />
              </Box>
            )}
            <RotationCostBreakdown
              profile={profile}
              hours={trueHours}
              discount={rotation?.discount}
              extraCharges={rotation?.extraCharges}
              total={total}
              initialTotal={initialTotal}
              dueToday={dueToday}
              dueLater={dueLater}
              hourlyRate={hourlyRate}
              serviceFee={serviceFee}
              lastMinuteFee={lastMinuteFee}
              paymentPlanFee={paymentPlanFee}
              previousRequestsPaidAmount={rotation?.previousRequestsPaidAmount}
              hideBreakdown={hideBreakdown}
            />
          </>
        )}
        {paymentType === rotationPaymentTypes.paymentPlan ? (
          <Box mt={2} mb={3}>
            <Typography variant="subtitle2">
              With the Payment Plan, you will make 5 payments: 1st - Application fee (Non-refundable)
              and next 4 equal payments of the total cost. After the Application fee deposit, we will
              invoice you separately for the balance of the rotation.You will be able to see your preceptor&apos;s
              contact info when Preceptor Signs Preceptor&apos;s Agreement. There will be a 15% payment plan
              fee, and you must be paid in full before Rotation start.
            </Typography>
          </Box>
        ) : (!hideBreakdown && (
          <Box mt={2} mb={3}>
            <PaymentsPercentageBreakdown
              total={total}
              previousRequestsPaidAmount={rotation?.previousRequestsPaidAmount}
            />
          </Box>
        ))}
        <Button variant="contained" size="large" fullWidth type="submit" disabled={disableReserveButton}>
          Reserve
          <ButtonProgress isLoading={loadingM} />
        </Button>
        <Box mt={1.5} display="flex" alignItems="center" justifyContent="center">
          <InfoIcon className={classes.icon} />
          <Typography variant="caption" color="textSecondary">
            {isPreceptorCancelled && 'Canceled preceptors can’t be reserved twice within the same rotation'}
            {rotationPaid && rotation?.status?.key !== rotationStatuses.refunded && 'There have already been payments for this rotation. Please contact PreceptorLink staff if any changes to dates are needed.'}
            {rotation?.status?.key === rotationStatuses.refunded && 'Payments on this rotation have been refunded. This rotation can no longer be modified.'}
            {!isPreceptorCancelled && !rotationPaid && 'You won’t be charged yet'}
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

ExpandedReservationBlock.defaultProps = {
  profile: {},
  preceptorID: '',
  availDates: [],
  honorariumHourRanges: [],
};

ExpandedReservationBlock.propTypes = {
  profile: PropTypes.shape({
    honorariumType: PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.string,
    }),
    amount: PropTypes.number,
    minimumHonorarium: PropTypes.number,
    hoursCapPerRotation: PropTypes.number,
  }),
  preceptorID: PropTypes.string,
  availDates: PropTypes.arrayOf(PropTypes.shape({})),
  honorariumHourRanges: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    amount: PropTypes.number,
  })),
  availSettings: PropTypes.shape({
    weekdays: PropTypes.arrayOf(PropTypes.number),
    days: PropTypes.arrayOf(PropTypes.shape({
      weekday: PropTypes.shape({
        key: PropTypes.number,
        value: PropTypes.string,
      }),
      start: PropTypes.shape({
        key: PropTypes.number,
        value: PropTypes.string,
      }),
      end: PropTypes.shape({
        key: PropTypes.number,
        value: PropTypes.string,
      }),
      hours: PropTypes.number,
    })),
  }).isRequired,
};

export default ExpandedReservationBlock;
