import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import clsx from 'clsx';
import periodicallyAvailableStyles from './styles';

const PeriodicallyAvailable = ({ rightRectangle }) => {
  const classes = periodicallyAvailableStyles();
  const title = 'Case-by-case availability';

  return (
    <Box className={clsx(classes.block, {
      [classes.right]: rightRectangle,
    })}
    >
      <QueryBuilderIcon fontSize="small" className={classes.iconClock} />
      <Typography variant="subtitle2" className={classes.partially}>
        {title}
      </Typography>
    </Box>
  );
};

PeriodicallyAvailable.defaultProps = {
  rightRectangle: false,
};

PeriodicallyAvailable.propTypes = {
  rightRectangle: PropTypes.bool,
};

export default PeriodicallyAvailable;
