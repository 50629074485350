import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@material-ui/core';

const ConfirmationDialog = ({
  open, title, content, handleCancel, handleConfirm,
}) => (
  <Dialog open={open} fullWidth maxWidth="xs" aria-labelledby="confirmation-dialog-title">
    <DialogTitle id="confirmation-dialog-title">
      <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>{title}</Typography>
    </DialogTitle>
    <DialogContent>
      <Typography>{content}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} variant="outlined">Cancel</Button>
      <Button onClick={handleConfirm} variant="contained">Confirm</Button>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
