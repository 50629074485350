import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Available from 'shared/components/PreceptorCard/partials/Available';
import NotAvailable from 'shared/components/PreceptorCard/partials/NotAvailable';
import PartiallyAvailable from 'shared/components/PreceptorCard/partials/PartiallyAvailable';
import CanceledByPreceptor from 'shared/components/PreceptorCard/partials/CanceledByPreceptor';
// import Divider from '@material-ui/core/Divider';
// import RateBlock from 'shared/components/PreceptorCard/partials/RateBlock';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import preceptorTitleStyles from './styles';
import PeriodicallyAvailable from '../PeriodicallyAvailable';

const PreceptorTitle = ({
  accountUID, hours, availability, statusCanceled, isPeriodicalAvailable, /* rate, */
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = preceptorTitleStyles();

  return (
    <Box className={classes.preceptorTitle}>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
      >
        <Box display="flex" className={statusCanceled ? classes.statusCanceled : ''}>
          <Typography className={classes.id}>
            Preceptor ID:&nbsp;
          </Typography>
          <Typography className={classes.idText}>
            {accountUID}
          </Typography>
        </Box>
        {/* <Box mx={isMobile ? 0 : 1.5} height={isMobile ? 0 : '22px'}>
          <Divider variant="fullWidth" />
        </Box>
        <Box>
          <RateBlock rate={rate} />
        </Box> */}
      </Box>
      {statusCanceled ? (
        <CanceledByPreceptor />
      ) : hours > 0 && (
        isPeriodicalAvailable ? (
          <>
            <PeriodicallyAvailable rightRectangle />
          </>
        ) : (
          <>
            {availability > 0 ? (
              <>
                {hours > availability ? (
                  <PartiallyAvailable hours={availability} rightRectangle />
                ) : (
                  <Available hours={hours} rightRectangle />
                )}
              </>
            ) : (
              <NotAvailable rightRectangle />
            )}
          </>
        )
      )}
    </Box>
  );
};

PreceptorTitle.defaultProps = {
  accountUID: '',
  hours: '',
  availability: '',
  statusCanceled: false,
  isPeriodicalAvailable: false,
  /* rate: '', */
};

PreceptorTitle.propTypes = {
  accountUID: PropTypes.string,
  hours: PropTypes.number,
  availability: PropTypes.number,
  statusCanceled: PropTypes.bool,
  isPeriodicalAvailable: PropTypes.bool,
  /* rate: PropTypes.number, */
};

export default PreceptorTitle;
