import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const ErrorFallback = ({ resetErrorBoundary }) => (
  <div style={{ justifyContent: 'center', textAlign: 'center' }}>
    <h2>Oops, something went wrong. Please reach out to us: </h2>
    <font style={{ color: 'rgb(42, 42, 42)', size: 2, textAlign: 'left' }}>
      <a href="mailto:administrative@preceptorlink.com">info@preceptorlink.com</a>
      <br />
      <a href="https://www.preceptorlink.com/">www.PreceptorLink.com</a>
      <br />
      <font color="#818181">
        <a href="tel:18884186620" title="">+1-888-418-6620</a>
        <br />
        <a href="tel:17606040913" title="">+1-760-604-0913</a>
      </font>
    </font>
    <br />
    <br />
    <br />
    <Button
      variant="contained"
      size="large"
      onClick={resetErrorBoundary}
    >
      Try again
    </Button>
  </div>
);

ErrorFallback.defaultProps = {
  error: {},
  resetErrorBoundary: {},
};

ErrorFallback.propTypes = {
  // eslint-disable-next-line
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
};

export default ErrorFallback;
